$(document).ready(function () {

  /* BackgroundCheck.init({
     targets: '.topnav'
   });*/

  // Switch langue
  $(".langue > a").click(function (e) {
    e.preventDefault();
    $(".sublangue").slideToggle();
  });

  $('a.prev-page').click(function () {
    parent.history.back();
    return false;
  });
  if ($(".slider-home").length > 0) {
    $('.slider-home').flexslider({
      controlNav: false,
      directionNav: false,
    });
  }
  $('.nav-main .navbar-toggler').click(function () {
    $(this).toggleClass('open');
  });

  //Adding fixed position to header
  $(document).scroll(function () {
    if ($(document).scrollTop() >= 550) {
      $('.block-search-top').addClass('fixed-search');

    } else {
      $('.block-search-top').removeClass('fixed-search');
    }
  });

  // fixed prev btn
  $(document).scroll(function () {
    if ($(document).scrollTop() >= 1) {
      $('html').addClass('has-fixed-nav');

    } else {
      $('html').removeClass('has-fixed-nav');
    }
  });
  //carousel team
  if ($(".team-carousel").length > 0) {
    $('.team-carousel').owlCarousel({
      loop: false,
      margin: 0,
      items: 3,
      autoplay: true,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: false
        },
        600: {
          items: 2,
          nav: false
        },
        1000: {
          items: 3,
          nav: true,
        }
      }
    })
  }
  //select bootstrap
  if ($(".selectpicker").length > 0) {
    $('.selectpicker').selectpicker();

    var url_string = window.location.href
    var url = new URL(url_string);
    var exp = url.searchParams.get("obj_expertise");
    var func = url.searchParams.get("obj_fonction");
    if (typeof exp !== "undefined" && null !== exp && '' !== exp) {

      $("#selectexpertises").closest('.bootstrap-select').find('.dropdown-toggle').addClass("active");
      $("#selectexpertises").closest('.bootstrap-select').append("<span class='closesearch closeexp'>  </span>");

      $('.closeexp').click(function (e) {
        e.preventDefault();
        $('#selectexpertises').val('').trigger('change');
      })

    }

    if (typeof func !== "undefined" && null !== func && '' !== func) {
      $("#selectfonctions").closest('.bootstrap-select').find('.dropdown-toggle').addClass("active");
      $("#selectfonctions").closest('.bootstrap-select').append("<span class='closesearch closefunc'>  </span>");

      $('.closefunc').click(function (e) {
        e.preventDefault();
        $('#selectfonctions').val('').trigger('change');
      })

    }

  }

  //autocomplite
  var searchRequest;
  $('.search-autocomplete').autoComplete({
    minChars: 2,
    source: function (term, suggest) {
      try { searchRequest.abort(); } catch (e) { }
      searchRequest = $.post(global.ajax, { search: term, action: 'search_site' }, function (res) {
        suggest(res.data);
      });
    }
  });


  //wow animation
  new WOW().init();


  // hover expertise
  $(".list-expertises li:first").addClass("active");
  $(".right-expertise .list-expert-img li:first").addClass("active");
  $(".left-expertise").css({ 'min-height': $('.right-expertise .list-expert-img li.active').innerHeight() });

  $(".list-expertises li").hover(function (e) {
    e.preventDefault();
    $(this).siblings('li.active').removeClass("active");
    $(this).addClass("active");
    var index = $(this).index();

    $(".right-expertise .list-expert-img li").removeClass("active");
    $(".right-expertise .list-expert-img li").eq(index).addClass("active");

    var boxHeight = $(".list-expert-img").eq(index).find("img").innerHeight();
    $("#expertise .right-expertise").height(boxHeight + "px");

  });

  //home list experts
  $(".home-experts li:first").addClass("active");
  $(".home-experts >.list-small-img li:first").addClass("active");

  $(".home-experts li").hover(function (e) {
    e.preventDefault();
    $(this).siblings('a.active').removeClass("active");
    $(this).addClass("active");
    var index = $(this).index();

    $(".list-small-img li").removeClass("active");
    $(".list-small-img li").eq(index).addClass("active");

  });


  $(".right-search .close").click(function (e) {
    e.preventDefault();
    $('.modal-body').fadeOut();

  });

  //sticky Sidebar
  if (screen.width > 767) {
    if ($("#sidebar").length > 0) {
      $('#sidebar').stickySidebar({
        topSpacing: 195,
        bottomSpacing: 150
      });
    }
  }

  //anim-image
  if ($(".js-tilt").length > 0) {
    $('.js-tilt').tilt({
      reset: true,
      perspective: 1500
    });
  }

  if ($(".teaminner").length > 0) {
    //random hover form
    var classes = ['leftform', 'rightform', 'centerform']; // the classes you want to add

    $(".teaminner").hover(
      function () {
        var randomClass = classes[Math.floor(Math.random() * classes.length)];

        $(this).addClass(randomClass);
      }, function () {
        $(this).removeClass();
      }
    );

  }

  //fond change color
  $('.section').scrollie({
    scrollOffset: -200,
    scrollingInView: function (elem) {

      var bgColor = elem.data('background');

      $('body').css('background-color', bgColor);

    }
  });



  //navbar color
  if (screen.width > 991) {
    $('.topnav').midnight();
  }
  // Wrap every letter in a span

  $('.ml3').waypoint(function () {

    anime.timeline({ loop: false })
      .add({
        targets: '.ml3',
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 3000,
        delay: function (el, i) {
          return 150 * (i + 1)
        }


      });

  }, {
      offset: '100%'
    });

  /*begin scroll */

  if ($(".block-chiffres").length > 0) {

    var html = document.documentElement;
    var body = document.body;

    var scroller = {
      target: document.querySelector(".page-template-page-etude .main-wrap"),
      ease: 0.3, // <= scroll speed
      endY: 0,
      y: 0,
      resizeRequest: 1,
      scrollRequest: 0,
    };

    var requestId = null;

    TweenLite.set(scroller.target, {
      rotation: 0.01,
      force3D: true
    });


    // scrollmagic
    // TweenLite.defaultEase = Linear.easeNone;
    var controller = new ScrollMagic.Controller();

    // build tween
    /*var tween = TweenMax.fromTo('.box-chiffres', 3, { transform: 'translateY(146.383px) rotate3d(1, 1, 0, 45deg)', ease:Linear.easeNone }, { transform: 'translateY(0) rotate3d(1, -1, 0, 0deg)', ease:Linear.easeNone });*/
    var tween = new TimelineMax();
    tween.fromTo('#ch-0', 3, { x: -200, opacity: 0, ease: Linear.easeNone }, { x: 0, opacity: 1, ease: Linear.easeNone }, 0)
      .fromTo('#ch-1', 3, { x: 200, y: -50, opacity: 0, ease: Linear.easeNone }, { x: 0, y: 0, opacity: 1, ease: Linear.easeNone }, 0)
      .fromTo('#ch-2', 3, { scale: 0.8, opacity: 0, ease: Linear.easeNone }, { scale: 1, opacity: 1, ease: Linear.easeNone }, 0)
      .fromTo('#ch-3', 3, { x: 200, opacity: 0, ease: Linear.easeNone }, { x: 0, opacity: 1, ease: Linear.easeNone }, 0)
      .fromTo('#ch-4', 3, { x: 200, opacity: 0, ease: Linear.easeNone }, { x: 0, opacity: 1, ease: Linear.easeNone }, 0)
      //.fromTo('#ch-4', 3, { x: -200, y: 200, opacity: 0, ease: Linear.easeNone }, { x: 0, y: 0, opacity: 1, ease: Linear.easeNone }, 0)
      .fromTo('#ch-5', 3, { x: 200, y: 150, opacity: 0, ease: Linear.easeNone }, { x: 0, y: 0, opacity: 1, ease: Linear.easeNone }, 0)
      .fromTo('#ch-6', 3, { x: -200, y: 200, opacity: 0, ease: Linear.easeNone }, { x: 0, y: 0, opacity: 1, ease: Linear.easeNone }, 0)
      .fromTo('#ch-8', 3, { x: 200, y: 150, opacity: 0, ease: Linear.easeNone }, { x: 0, y: 0, opacity: 1, ease: Linear.easeNone }, 0)
    // build scene
    var scene = new ScrollMagic.Scene({ triggerElement: "#trigger3", duration: 600, offset: -300 })
      .setTween(tween)
      .setClassToggle("#Chiffres-cles", "notactive")
      .addTo(controller);


    window.addEventListener("load", onLoad);

    function onLoad() {
      updateScroller();
      window.focus();
      window.addEventListener("resize", onResize);
      document.addEventListener("scroll", onScroll);
    }

    function updateScroller() {

      var resized = scroller.resizeRequest > 0;

      if (resized) {
        var height = scroller.target.clientHeight;
        body.style.height = height + "px";
        scroller.resizeRequest = 0;
      }

      var scrollY = window.pageYOffset || html.scrollTop || body.scrollTop || 0;

      scroller.endY = scrollY;
      scroller.y += (scrollY - scroller.y) * scroller.ease;

      if (Math.abs(scrollY - scroller.y) < 0.15 || resized) {
        scroller.y = scrollY;
        scroller.scrollRequest = 0;
      }

      TweenLite.set(scroller.target, {
        y: -scroller.y
      });

      requestId = scroller.scrollRequest > 0 ? requestAnimationFrame(updateScroller) : null;
    }

    function onScroll() {
      scroller.scrollRequest++;
      if (!requestId) {
        requestId = requestAnimationFrame(updateScroller);
      }
    }

    function onResize() {
      scroller.resizeRequest++;
      if (!requestId) {
        requestId = requestAnimationFrame(updateScroller);
      }
    }

  }

  /*end scroll */

});

